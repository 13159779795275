.group-select-field {
  .ant-select-item-group {
    font-weight: bold;
    color: #1890ff;
    background-color: #e6f7ff;
    padding: 8px 12px;
  }

  .ant-select-item-option {
    padding-left: 24px;
  }
}

.ant-select-dropdown {
    padding: 8px;
    border: 1px solid #f0f0f0;
    border-radius: 4px;
    box-shadow: 0 3px 6px rgba(0, 0, 0, 0.16);
    .dropdown-footer {
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 8px;
    border-top: 1px solid #f0f0f0;
    background-color: #fafafa;
  }

  .dropdown-footer .ant-typography-secondary {
    flex-grow: 1;
    margin-right: 8px;
    font-size: 12px;
    color: #8c8c8c;
  }
  }

